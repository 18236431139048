import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_solutions.js";
import { Link } from "gatsby";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p className="leading-relaxed text-lg">
  Connect your digital content in Trailguide to print-products such as maps,
  brochures, and outdoor signs. Your guests just need to scan a{" "}
  <a href="https://en.wikipedia.org/wiki/QR_code" target="wiki">
    QR-code
  </a>{" "}
  with his or her phone and it will open up the web-app. All information and
  functions are instantly available on the phone without the barrier of
  downloading, installing and registering an account for a native app.
    </p>
    <Image src="solutions/printproducts.png" className="my-12 w-3/4 mx-auto" mdxType="Image" />
    <h4>{`Connect to print products`}</h4>
    <p>{`Connect your digital content to print products. A paper map provides a great
overview over an area. With a QR-code you can link physical map with the online
digital content in Trailguide, showing the same activities as on paper, but
enabling you to provide updated and current information. The same can be done
with brochures, magazines, flyers, stickers, outdoor signs, and more.`}</p>
    <br />
    <br />
    <h4>{`Signposts`}</h4>
    <p>{`Connecting signs with digital content opens many possibilities. The space on a
sign is limited. With a small QR-code you can directly link to the information
of the specific activity. Provide a digital map, navigation tool, description,
pictures, latest conditions & more.`}</p>
    <br />
    <p>{`Printed information can get outdated fast. A digital version of the content is
easy to keep up to date. Guests can see the latest condition reports, the
status of the activity, warnings for forestry work, trail building activities,
information about hunting, etc.`}</p>
    <Image src="solutions/signpost3.png" className="mb-12 w-3/4 mx-auto" mdxType="Image" />
    <br />
    <br />
    <h4>{`Rental & test bikes`}</h4>
    <p>{`Place a small sticker with a QR code on the frames of your rental bikes. Your
guests will always have access to your available tours by scanning the QR
codes on their bikes. You can create customized maps and collections for
beginners, intermediate and advanced riders.`}</p>
    <br />
    <p>{`Guests often to not know the area when renting bikes. With Trailguide they get to
know where to ride, and a navigation tool to find the tours.`}</p>
    <Image src="nesbyen_enduro_2018/nesbyen2018-14.jpg" className="my-12 w-3/4 mx-auto" mdxType="Image" />
    <br />
    <br />
    <h4>{`Customized collections`}</h4>
    <p>{`Create customized collections with a map and list of your exlusive content and have
full control of what is shown to your guests.`}</p>
    <br />
    <p>{`Provide an overview over all your activities, or show specific ones only.
You can create an unlimited number of customized collections.`}</p>
    <br />
    <p>{`Select the tracks and points of interest you want to show. Choose the map
style and the visible map section.`}</p>
    <br />
    <p>{`Every map has a uniqe URL such as `}<a href="https://trailguide.net/#1T" target="tg">{`trailguide.net#1T`}</a>{`
and a QR-code pointing to the URL. The QR-code will never change once you have
made it, so you can safely modify the content of the map without the need to
replace your printed material.`}</p>
    <br />
    <Image src="solutions/mapeditor.png" className="my-12 w-3/4 mx-auto" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      